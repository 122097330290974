import React, { useContext, useEffect, useState } from "react";
import { Alert, Box, Grid, Tab, Skeleton } from '@mui/material';
import { isMobileOnly } from 'react-device-detect';
import { TabContext, TabList, TabPanel } from "@mui/lab";
import Image from "../../../shop/reusable/Image";
import ProductInterfaceCodes from "./ProductInterfaceCodes";
import ProductExports from "./ProductExports";
import ProductAttributes from "../ProductAttributes";
import { useQuery } from "@tanstack/react-query";
import axiosRequest from "../../../axios/axoisRequest";
import config from "../../../config";
import { UserContext } from "../../../store/UserContext";
import OpenAISpeedFeed from "../../../ai/OpenAISpeedFeed";

const ProductTabs = ({ categoryImage, closeDialog, isFetching, selectedProduct, fullHeight }) => {

    const { user } = useContext(UserContext);
    const [tab, setTab] = useState("1");
    const [options, setOptions] = useState();
    const [codeCount, setCodeCount] = useState();
    const [codes, setCodes] = useState();

    useEffect(() => {
        if (window.location.search) {
            let urlParams = new URLSearchParams(window.location.search);
            let tab = urlParams.get("product_tab")
            if (tab) {
                setTab(tab)
            } else {
                setTab('1')
            }
        } else {
            setTab('1')
        }
    }, [])

    const handleChange = (event, newValue) => {
        setTab(newValue);
    };

    const { data } = useQuery(['products', selectedProduct?.product_cim_id],
        ({ signal }) => axiosRequest({
            signal: signal,
            gateway: config.shopAPI.URL,
            endpoint: 'products/full',
            api_key: user.api_key,
            method: 'get',
            params: {
                id: selectedProduct.product_cim_id
            }
        }), {
        enabled: !!selectedProduct?.product_cim_id,
        retry: 1
    })

    useEffect(() => {
        if (data) {
            setCodes(data?.InterfaceCodes)
            setCodeCount(data?.InterfaceCodes.length)
        }
    }, [data])

    const { data: exports } = useQuery({
        queryKey: ['exports', selectedProduct?.product_cim_id],
        queryFn: ({ signal }) => axiosRequest({
            signal: signal,
            gateway: config.shopAPI.URL,
            endpoint: 'products/exports',
            api_key: user.api_key,
            method: 'get',
            params: {
                id: selectedProduct?.product_cim_id
            }
        }),
        enabled: !!selectedProduct?.product_cim_id,
        retry: 1
    })

    useEffect(() => {
        if (exports) {
            setOptions(exports)
        }
    }, [exports])

    return (

        <Grid item xs={12} sx={{ padding: isMobileOnly ? '1em' : '1em 2em 2em 2em', width: '100%' }}>
            <TabContext

                value={tab}>

                <Box sx={{ borderBottom: 1, borderColor: 'divider', width: '100%' }}>
                    <TabList onChange={handleChange} aria-label="Tabs">
                        <Tab sx={{ fontSize: '1em' }} label="Product Features" value="1" />
                        {selectedProduct?.product_cim_id ?
                            <Tab sx={{ fontSize: '1em' }} label={`Matching Components${codeCount ? ` (${codeCount})` : ''}`} value="2" />
                            : null}
                        {/* {variants && variants.length > 1 ?
                            <Tab sx={{ fontSize: '1.2em' }} label={`Variants ${variants ? `(${variants.length})` : ''}`} value="3" />
                            : null} */}
                        {selectedProduct?.product_cim_id && options ?
                            <Tab sx={{ fontSize: '1em' }} label={`Downloads${options && options.length > 0 ? ` (${options.length})` : ""}`} value="4" />
                            : null}
                        {/* {product?.product_cim_id ?
                            <Tab sx={{ fontSize: '1.2em' }} label={`Accessories${allAccessories?.length > 0 ? ` (${allAccessories.length})` : ''}`} value="5" />
                            : null} */}
                        {selectedProduct?.product_attributes && selectedProduct?.product_cim_id ?
                            <Tab sx={{ fontSize: '1em' }} label={`Speeds & Feeds`} value="5" />
                            : null}

                    </TabList>
                </Box>

                <TabPanel
                    sx={{
                        background: (theme) => theme.palette.background.paper,
                        borderLeft: '1px solid rgba(0, 0, 0, 0.12)',
                        borderRight: '1px solid rgba(0, 0, 0, 0.12)',
                        borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
                    }}
                    value="1"
                >
                    <Grid container spacing={2}>
                        {selectedProduct?.product_attributes ?
                            <Grid item xs={12} md={categoryImage ? 8 : 12}>
                                <ProductAttributes attributes={selectedProduct?.product_attributes} fullHeight={fullHeight} />
                            </Grid>
                            : isFetching ?
                                <Grid item xs={12} >
                                    <Skeleton height={35} />
                                    <Skeleton height={35} />
                                    <Skeleton height={35} />
                                </Grid>
                                :
                                <Grid item xs={12} >
                                    <Alert variant="standard" severity="info">No Features Available</Alert>
                                </Grid>

                        }
                        {categoryImage ?
                            <Grid item xs={12} md={4}>
                                <Image height="100%" isFetching={false} src={categoryImage?.url} alt={selectedProduct?.product_name} ShowIcon={false} zoom />
                            </Grid>
                            : null}
                    </Grid>
                </TabPanel>

                {selectedProduct?.product_cim_id && codes ?
                    <TabPanel
                        sx={{
                            background: (theme) => theme.palette.background.paper,
                            borderLeft: '1px solid rgba(0, 0, 0, 0.12)',
                            borderRight: '1px solid rgba(0, 0, 0, 0.12)',
                            borderBottom: '1px solid rgba(0, 0, 0, 0.12)'
                        }}
                        value="2"
                    >
                        {codes.length > 0 ?
                            <ProductInterfaceCodes codes={codes} selectedProduct={selectedProduct} closeDialog={closeDialog} />
                            : codes.length === 0 ?
                                <Alert variant="standard" severity="info">No Matching Components Available</Alert>
                                : null}
                    </TabPanel>
                    : null}
                {selectedProduct?.product_cim_id && options ?
                    <TabPanel
                        sx={{
                            background: (theme) => theme.palette.background.paper,
                            borderLeft: '1px solid rgba(0, 0, 0, 0.12)',
                            borderRight: '1px solid rgba(0, 0, 0, 0.12)',
                            borderBottom: '1px solid rgba(0, 0, 0, 0.12)'
                        }}
                        value="4"
                    >
                        {options ?
                            <ProductExports options={options} setOptions={setOptions} product={selectedProduct} />
                            : selectedProduct?.product_downloads && selectedProduct?.product_downloads.length === 0 ?
                                <Alert variant="standard" severity="info">No Downloads Available</Alert>
                                : null}
                    </TabPanel>
                    : null}
                {/* {selectedProduct?.product_cim_id ?
                    <TabPanel
                        sx={{
                            background: (theme) => theme.palette.background.paper,
                            borderLeft: '1px solid rgba(0, 0, 0, 0.12)',
                            borderRight: '1px solid rgba(0, 0, 0, 0.12)',
                            borderBottom: '1px solid rgba(0, 0, 0, 0.12)'
                        }}
                        value="5"
                    >
                        {allAccessories && Object.values(allAccessories).length ?
                            allAccessories.map((product, i) =>
                                <AccessoriesList key={i} product={product} isFetching={isFetching} />
                            )
                            : <Alert variant="standard" severity="info">No Accessories Available</Alert>}
                    </TabPanel>
                    : null} */}
                {selectedProduct?.product_attributes && selectedProduct?.product_cim_id ?
                    <TabPanel
                        sx={{
                            background: (theme) => theme.palette.background.paper,
                            borderLeft: '1px solid rgba(0, 0, 0, 0.12)',
                            borderRight: '1px solid rgba(0, 0, 0, 0.12)',
                            borderBottom: '1px solid rgba(0, 0, 0, 0.12)'
                        }}
                        value="5"
                    >
                        <OpenAISpeedFeed product={selectedProduct} />
                    </TabPanel>
                    : null}
            </TabContext>
        </Grid >
    );
};
export default ProductTabs;
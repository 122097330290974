import React, { useContext, useState } from "react";
import axios from "axios";
import ReactHtmlParser from 'html-react-parser';
import { Alert, Autocomplete, Avatar, Button, Divider, Grid, IconButton, InputAdornment, ListItem, ListItemAvatar, ListItemButton, ListItemText, TextField, Typography } from "@mui/material";
import { UserContext } from "../store/UserContext";
import { useMutation } from "@tanstack/react-query";
import axiosRequest from "../axios/axoisRequest";
import config from "../config";
import SpinningLoader from "../reusable/SpinnningLoader";
import Materials from "../products/shared/Materials";
import { trackEvent } from "../google_analytics/ga";
import { CompanyContext } from "../store/CompanyContext";

const materialList = [
    { "label": "Steel", "value": "Steel", "colour": "#00B5FF", "group": "P" },
    { "label": "Stainless Steel", "value": "Stainless Steel", "colour": "#FFFF32", "group": "M" },
    { "label": "Cast Iron", "value": "Cast Iron", "colour": "#ff4400", "group": "K" },
    { "label": "Non-Ferrous Material", "value": "Non-Ferrous Material", "colour": "#1CEA00", "group": "N" },
    { "label": "Heat Resistant Alloys / Titanium", "value": "Heat Resistant Alloys / Titanium", "colour": "#FFA02F", "group": "S" },
    { "label": "Hardend Steel", "value": "Hardend Steel", "colour": "#B9B9B9", "group": "H" },
]

const unitList = [{ "label": "Metric", "value": "Metric" }, { "label": "Imperial", "value": "Imperial" }]

export default function OpenAISpeedFeed({ product }) {

    const [material, setMaterial] = useState();
    const [units, setUnits] = useState(unitList[0]);
    const [response, setResponse] = useState();
    const { user } = useContext(UserContext);
    const { selectedCompany } = useContext(CompanyContext);

    console.log(user)

    // console.log(product)
    // const handleMaterialChange = (e) => setMaterial(e.target.value);

    const getDetails = useMutation({
        mutationKey: ['openai', material, product.product_id],
        mutationFn: (messages) => {
            //console.log(basket)
            return axiosRequest({
                gateway: config.factoryAPI.URL,
                endpoint: 'openai',
                api_key: user.api_key,
                method: 'post',
                body: {
                    model: "gpt-4o-mini", // or "gpt-4" if you have access
                    messages: messages,
                    max_tokens: 800, // Adjust based on your needs
                }
            })
        },
        onSuccess: (context, variables) => {
            console.log("context", context)
            if (!context.errorMessage) {
                setResponse(context);
            }
        }
    })


    const getAIResponse = (product, material, units) => {

        //Construct the message prompt including OpenSearch data
        const messages = [
            {
                role: "system",
                content: "You are a machining expert. Given an product and material, your task is to return the recommended speeds and feeds for machining in the correct measurement systems in a structured HTML format. Only provide the data if you have verified information for the specific product and material thats is being cut. If the information is not available, return an appropriate message indicating that the data is not available. Also, include the source of the data if available. Where possible get the information from the manufacturer. Use the following HTML template for the output, and return only the body content (without DOCTYPE, html, head, or body tags):\n\n" +
                    "<h2>Speeds and Feeds for {product_name} Product ({material_name})</h2>\n" +
                    "<h3>General Guidelines:</h3>\n" +
                    "<ul>\n" +
                    "  <li><strong>Cutting Speed ({cutting_formular}):</strong> {cutting_speed}</li>\n" +
                    "  <li><strong>Feed Rate ({cutting_formular}):</strong> {feed_rate}</li>\n" +
                    "  <li><strong>Depth of Cut ({cutting_formular}):</strong> {depth_of_cut}</li>\n" +
                    "</ul>\n" +
                    "<h3>Roughing:</h3>\n" +
                    "<ul>\n" +
                    "  <li><strong>Cutting Speed ({cutting_formular}):</strong> {roughing_cutting_speed}</li>\n" +
                    "  <li><strong>Feed Rate ({cutting_formular}):</strong> {roughing_feed_rate}</li>\n" +
                    "  <li><strong>Depth of Cut ({cutting_formular}):</strong> {roughing_depth_of_cut}</li>\n" +
                    "</ul>\n" +
                    "<h3>Finishing:</h3>\n" +
                    "<ul>\n" +
                    "  <li><strong>Cutting Speed ({cutting_formular}):</strong> {finishing_cutting_speed}</li>\n" +
                    "  <li><strong>Feed Rate ({cutting_formular}):</strong> {finishing_feed_rate}</li>\n" +
                    "  <li><strong>Depth of Cut ({cutting_formular}):</strong> {finishing_depth_of_cut}</li>\n" +
                    "</ul>\n" +
                    "<p><small><strong>Source:</strong> {source}</small></p>"
            },
            {
                role: "user",
                content: `Product code: ${product.product_number}  
                Product description: ${product.product_description} 
                Product manufacturer: ${product.product_manufacturer} 
                Cutting material Details: ${JSON.stringify(material)}
                Measurement system: ${units.value}
                `
            }
        ];


        //add to google analytics
        trackEvent({
            action: 'openai_search',
            params: {
                "company_id": selectedCompany.company_id,
                "items": [{
                    "item_id": product.product_id,
                    "item_name": product.product_description,
                    "product_number": product.product_number,
                    "product_supplier": product.product_supplier
                }]
            }
        })

        //console.log(messages)

        getDetails.mutate(messages)

        // try {
        //     const result = await axios.post(
        //         "https://api.openai.com/v1/chat/completions",
        //         {
        //             model: "gpt-4o-mini", // or "gpt-4" if you have access
        //             messages: messages,
        //             max_tokens: 800, // Adjust based on your needs
        //         },
        //         {
        //             headers: {
        //                 Authorization: `Bearer `,
        //             },
        //         }
        //     );

        //     // Parse the response as HTML
        //     setResponse(result.data.choices[0].message.content);

        // } catch (error) {
        //     console.error("Error fetching data from OpenAI", error);
        // }
    };

    console.log('material', material)

    return (
        <Grid container spacing={2}>
            {
                !user?.user?.user_permissions?.administrator ?
                    <Grid item xs={12}>
                        <Alert variant="standard" severity="warning">This feature is in Beta testing and is only available to administrators, but will be available for everyone soon.</Alert>
                    </Grid>
                    : null
            }
            {user.user.user_permissions.administrator ?
                <>
                    <Grid item xs={12}>
                        <Typography textAlign="center" variant="p">Please specify the material you are cutting below.</Typography>
                    </Grid>

                    <Grid item xs={12}>
                        <Materials setMaterial={setMaterial} material={material} />
                    </Grid>
                    <Grid item xs={12}>
                        <Button
                            sx={{
                                height: '56px'
                            }}
                            fullWidth
                            variant="contained"
                            color="primary"
                            size="large"
                            onClick={() => getAIResponse(product, material, units)}
                            disabled={!material && !user.user.user_permissions.administrator}
                        >Get Information for cutting {material?.Designation ? material?.Designation : material} </Button>
                    </Grid>
                </>
                : null}
            <Divider />
            <Grid item xs={12}>

                {getDetails.isLoading ?
                    <SpinningLoader text="Generating details..." size={40} />
                    : response ?
                        <>
                            {ReactHtmlParser(response)}
                            <Alert variant='standard' severity='warning'>The exact speed and feed values may vary depending on the material condition, machine rigidity, and specific operation. The speed and feed values supplied are a guide only based on the information submitted. Please consult your local {config.APPNAME === 'cis' ? 'CIS' : 'ACT'} Technical Solutions Engineer for further technical support and speed and feed optimisation.</Alert>
                        </>
                        : getDetails.isLoading ?
                            <SpinningLoader text="Generating details..." size={40} />
                            : null
                }
            </Grid>

        </Grid >
    );
}
